import React from 'react'
import { Link } from 'react-router-dom'

export default function Event_details() {
    return (
        <div>
            <section className="innerPagebanner">
                <div className="container">
                    <div className="wraper">
                        <div className="row">
                            <div className="content">
                                <h1>Events Details</h1>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><span>-</span></li>
                                    <li><Link to="/events">News &amp; Events</Link></li>
                                    <li><span>-</span></li>
                                    <li><Link to="/event-details">Details</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ss-buts" id="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-12 colLeft">
                            <div className="content">
                                <h4><span>Strengthening Bilateral <br />
                                    Relations and Serving Our Citizens</span>
                                </h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-12 slidbuts"> </div>
                        <div className="col-lg-6 col-12 colRight">
                            <ul className="social">
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-linkedin" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-twitter" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-instagram" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-facebook" aria-hidden="true" /></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="event-details">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-12 eve-left-maincol">
                            <div className="eve-detail-img">
                                <img src="./images/event-details-img.png" alt="Event Details" />
                            </div>
                            <div className="eve ctc-wrap">
                                <div className="row">
                                    <div className="col-lg-9 col-md-9 col-12">
                                        <h2>dolor sit amet consecteutr <br />elit sed do quis</h2>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-12">
                                        <div className="user-box">
                                            <div className="user-img">
                                                <img src="./images/admin1.png" alt="Admin" />
                                            </div>
                                            <div className="user-name">
                                                <h6>lora stevenson</h6>
                                                <span><strong>June 12</strong></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 desc">
                                    <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
                                        text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has
                                        survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in
                                        the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishi.Lorem Ipsum is
                                        simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the
                                        1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five
                                        centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
                                        release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishi.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="sidbar">
                                <div className="searchbox">
                                    <form action method="post">
                                        <input type="text" name placeholder="Enter Search Keywords" />
                                        <button type="submit" name="submit"><i className="fa fa-search" aria-hidden="true" /></button>
                                    </form>
                                </div>
                                <div className="header">
                                    <h3>Recent Events</h3>
                                </div>
                                {/* events posts list  */}
                                <div className="post-box">
                                    <div className="row mb-4">
                                        <div className="col-lg-3 col-md-3 col-12">
                                            <div className="post-img">
                                                <Link to="/events">
                                                    <img src="./images/post_side1.png" alt="News" />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-9 col-md-9 col-12">
                                            <div className="post-content">
                                                <Link to="/events">
                                                    <h5>dolor sit amet consecteutr <br /> elit sed do quis</h5>
                                                    <div className="user-box">
                                                        <div className="user-img">
                                                            <img src="./images/admin1.png" alt="Admin" />
                                                        </div>
                                                        <div className="user-name">
                                                            <h6>lora stevenson</h6>
                                                            <span><strong>June 12</strong></span>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <Link to="/events" className="copy"><img src="./images/copy.png" alt="Copy" /></Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-lg-3 col-md-3 col-12">
                                            <div className="post-img">
                                                <Link to="/events">
                                                    <img src="./images/post_side2.png" alt="News" />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-9 col-md-9 col-12">
                                            <div className="post-content">
                                                <Link to="/events">
                                                    <h5>dolor sit amet consecteutr <br /> elit sed do quis</h5>
                                                    <div className="user-box">
                                                        <div className="user-img">
                                                            <img src="./images/admin1.png" alt="Admin" />
                                                        </div>
                                                        <div className="user-name">
                                                            <h6>lora stevenson</h6>
                                                            <span><strong>June 12</strong></span>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <Link to="/events" className="copy"><img src="./images/copy.png" alt="Copy" /></Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-lg-3 col-md-3 col-12">
                                            <div className="post-img">
                                                <Link to="/event-details">
                                                    <img src="./images/post_side3.png" alt="News" />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-9 col-md-9 col-12">
                                            <div className="post-content">
                                                <Link to="/event-details">
                                                    <h5>dolor sit amet consecteutr <br /> elit sed do quis</h5>
                                                    <div className="user-box">
                                                        <div className="user-img">
                                                            <img src="./images/admin1.png" alt="Admin" />
                                                        </div>
                                                        <div className="user-name">
                                                            <h6>lora stevenson</h6>
                                                            <span><strong>June 12</strong></span>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <Link to="/events" className="copy"><img src="./images/copy.png" alt="Copy" /></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* events posts list  */}
                                <div className="heads-tags">
                                    <h3>Tags</h3>
                                </div>
                                <div className="tags">
                                    <ul>
                                        <li><Link to="#">lorem ipsum</Link></li>
                                        <li><Link to="#">ipsum</Link></li>
                                        <li><Link to="#">lorem</Link></li>
                                        <li><Link to="#">Dummy</Link></li>
                                        <li><Link to="#">lorem ipsum Dolor</Link></li>
                                        <li><Link to="#">Text</Link></li>
                                        <li><Link to="#">Dolor Sit</Link></li>
                                        <li><Link to="#">Dummy</Link></li>
                                        <li><Link to="#">lorem ipsum</Link></li>
                                        <li><Link to="#">ipsum</Link></li>
                                        <li><Link to="#">lorem</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
