import React from 'react'
import { Link } from 'react-router-dom'

export default function Services() {
    return (
        <div>
            <section className="innerPagebanner">
                <div className="container">
                    <div className="wraper">
                        <div className="row">
                            <div className="content">
                                <h1>Consular Services</h1>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><span>-</span></li>
                                    <li><Link to="/services">Consular Services</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ss-buts" id="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-12 colLeft">
                            <div className="content">
                                <h4><span>Strengthening Bilateral <br />
                                    Relations and Serving Our Citizens</span>
                                </h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-12 slidbuts"> </div>
                        <div className="col-lg-6 col-12 colRight">
                            <ul className="social">
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-linkedin" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-twitter" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-instagram" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-facebook" aria-hidden="true" /></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services" id="servicsPage">
                <div className="container">
                    <div className="heatest text-center">
                        <h2>Consular Services</h2>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been <br />
                            the industry's standard dummy text.
                        </p>
                    </div>
                    <div className="row ssrow1">
                        <div className="item col-lg-4 col-md-4 col-12">
                            <Link to="/visa-application">
                            </Link><div className="box"><Link to="/visa-application">
                                <div className="ss-img">
                                    <h6>Visa Applications</h6>
                                    <img src="./images/visaService.png" alt="Visa Applications" />
                                </div>
                            </Link><div className="ss-content"><Link to="/visa-application">
                                <h3>Visa Applications</h3>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisic-<br />ing elit, sed do eiusmod tempor incididunt ut <br />   labore et dolore magna aliqua. Ut enim
                                </p>
                            </Link><div className="butns"><Link to="/visa-application">
                            </Link><Link to="/visa-application" className="p-btn" tabIndex={0}><span>View Services</span><img src="./images/red-btn.png" alt="Go Btn" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item col-lg-4 col-md-4 col-12">
                            <Link to="/service-details">
                            </Link><div className="box"><Link to="/service-details">
                                <div className="ss-img">
                                    <h6>Birth Certificates</h6>
                                    <img src="./images/certificate-services.png" alt="Certificates Services" />
                                </div>
                            </Link><div className="ss-content"><Link to="/service-details">
                                <h3>Birth Certificates</h3>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisic-<br />ing elit, sed do eiusmod tempor incididunt ut <br />   labore et dolore magna aliqua. Ut enim
                                </p>
                            </Link><div className="butns"><Link to="/service-details">
                            </Link><Link to="/service-details" className="p-btn" tabIndex={0}><span>View Services</span><img src="./images/red-btn.png" alt="Go Btn" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item col-lg-4 col-md-4 col-12">
                            <Link to="/service-details">
                            </Link><div className="box"><Link to="/service-details">
                                <div className="ss-img">
                                    <h6>Marriage Certificates</h6>
                                    <img src="./images/MarriageCertificates.jpg" alt="Certificates Services" />
                                </div>
                            </Link><div className="ss-content"><Link to="/service-details">
                                <h3>Marriage Certificates</h3>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisic-<br />ing elit, sed do eiusmod tempor incididunt ut <br />   labore et dolore magna aliqua. Ut enim
                                </p>
                            </Link><div className="butns"><Link to="/service-details">
                            </Link><Link to="/service-details" className="p-btn" tabIndex={0}><span>View Services</span><img src="./images/red-btn.png" alt="Go Btn" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div class="item col-lg-4 col-md-4 col-12">
                      <Link to="/service-details">
                          <div class="box">
                              <div class="ss-img">
                                  <h6>Consular Services</h6>
                                  <img src="./images/consularService.png" alt="Consular Services" />
                              </div>
                              <div class="ss-content">

                                  <h3>Consular Services</h3>
                                  <p>
                                      Lorem ipsum dolor sit amet, consectetur adipisic-<br>ing elit, sed do eiusmod tempor incididunt ut <br>   labore et dolore magna aliqua. Ut enim
                                      </p>
                                          <div class="butns">
                                              <Link to="/service-details" class="p-btn" tabindex="0"
                                              ><span>View Services</span
                                              ><img src="./images/red-btn.png" alt="Go Btn"
                                                  /></Link>
                                          </div>
                                      </div>
                              </div>
                      </Link>
                  </div> */}
                    </div>
                    <div className="row ssrow1">
                        <div className="item col-lg-4 col-md-4 col-12">
                            <Link to="/service-details">
                            </Link><div className="box"><Link to="/service-details">
                                <div className="ss-img">
                                    <h6>Death Certificates</h6>
                                    <img src="./images/DeathCertificate.png" alt="Death Certificates and Transfer of Remains" />
                                </div>
                            </Link><div className="ss-content"><Link to="/service-details">
                                <h3>Death Certificates and Transfer of Remains</h3>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisic-<br />ing elit, sed do eiusmod tempor incididunt ut <br />   labore et dolore magna aliqua. Ut enim
                                </p>
                            </Link><div className="butns"><Link to="/service-details">
                            </Link><Link to="/service-details" className="p-btn" tabIndex={0}><span>View Services</span><img src="./images/red-btn.png" alt="Go Btn" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item col-lg-4 col-md-4 col-12">
                            <Link to="/service-details">
                            </Link><div className="box"><Link to="/service-details">
                                <div className="ss-img">
                                    <h6>Life and Maintenance Certificates</h6>
                                    <img src="./images/LifeandMaintenance.png" alt="Life and Maintenance Certificates" />
                                </div>
                            </Link><div className="ss-content"><Link to="/service-details">
                                <h3>Life and Maintenance Certificates</h3>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisic-<br />ing elit, sed do eiusmod tempor incididunt ut <br />   labore et dolore magna aliqua. Ut enim
                                </p>
                            </Link><div className="butns"><Link to="/service-details">
                            </Link><Link to="/service-details" className="p-btn" tabIndex={0}><span>View Services</span><img src="./images/red-btn.png" alt="Go Btn" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item col-lg-4 col-md-4 col-12">
                            <Link to="/service-details">
                            </Link><div className="box"><Link to="/service-details">
                                <div className="ss-img">
                                    <h6>Laissez-Passer/Travel Documents</h6>
                                    <img src="./images/passportService.png" alt="Passport Services" />
                                </div>
                            </Link><div className="ss-content"><Link to="/service-details">
                                <h3>Laissez-Passer/Travel Documents</h3>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisic-<br />ing elit, sed do eiusmod tempor incididunt ut <br />   labore et dolore magna aliqua. Ut enim
                                </p>
                            </Link><div className="butns"><Link to="/service-details">
                            </Link><Link to="/service-details" className="p-btn" tabIndex={0}><span>View Services</span><img src="./images/red-btn.png" alt="Go Btn" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row ssrow1">
                        <div className="item col-lg-4 col-md-4 col-12">
                            <Link to="/service-details">
                            </Link><div className="box"><Link to="/service-details">
                                <div className="ss-img">
                                    <h6>Legalization of Commercial Documents </h6>
                                    <img src="./images/legalService.png" alt="Legal and Notarial Services" />
                                </div>
                            </Link><div className="ss-content"><Link to="/service-details">
                                <h3>Procurations and Legalization of Commercial Documents</h3>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisic-<br />ing elit, sed do eiusmod tempor incididunt ut <br />   labore et dolore magna aliqua. Ut enim
                                </p>
                            </Link><div className="butns"><Link to="/service-details">
                            </Link><Link to="/service-details" className="p-btn" tabIndex={0}><span>View Services</span><img src="./images/red-btn.png" alt="Go Btn" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item col-lg-4 col-md-4 col-12">
                            <Link to="/service-details">
                            </Link><div className="box"><Link to="/service-details">
                                <div className="ss-img">
                                    <h6> Legalization of Diplomas </h6>
                                    <img src="./images/Diplomas.png" alt=" Legalization of Diplomas" />
                                </div>
                            </Link><div className="ss-content"><Link to="/service-details">
                                <h3> Legalization of Diplomas</h3>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisic-<br />ing elit, sed do eiusmod tempor incididunt ut <br />   labore et dolore magna aliqua. Ut enim
                                </p>
                            </Link><div className="butns"><Link to="/service-details">
                            </Link><Link to="/service-details" className="p-btn" tabIndex={0}><span>View Services</span><img src="./images/red-btn.png" alt="Go Btn" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item col-lg-4 col-md-4 col-12">
                            <Link to="/service-details">
                            </Link><div className="box"><Link to="/service-details">
                                <div className="ss-img">
                                    <h6> Consular Card </h6>
                                    <img src="./images/consular-card.png" alt=" Consular Card" />
                                </div>
                            </Link><div className="ss-content"><Link to="/service-details">
                                <h3>  Consular Card</h3>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisic-<br />ing elit, sed do eiusmod tempor incididunt ut <br />   labore et dolore magna aliqua. Ut enim
                                </p>
                            </Link><div className="butns"><Link to="/service-details">
                            </Link><Link to="/service-details" className="p-btn" tabIndex={0}><span>View Services</span><img src="./images/red-btn.png" alt="Go Btn" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row ssrow2">
                        <div className="item col-lg-4 col-md-4 col-12">
                            <Link to="/service-details">
                            </Link><div className="box"><Link to="/service-details">
                                <div className="ss-img">
                                    <h6>Legal and Notarial Services </h6>
                                    <img src="./images/LegalNotarial.png" alt="Legal and Notarial Services" />
                                </div>
                            </Link><div className="ss-content"><Link to="/service-details">
                                <h3>Legal and Notarial Services</h3>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisic-<br />ing elit, sed do eiusmod tempor incididunt ut <br />   labore et dolore magna aliqua. Ut enim
                                </p>
                            </Link><div className="butns"><Link to="/service-details">
                            </Link><Link to="/service-details" className="p-btn" tabIndex={0}><span>View Services</span><img src="./images/red-btn.png" alt="Go Btn" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item col-lg-4 col-md-4 col-12">
                            <Link to="/service-details">
                            </Link><div className="box"><Link to="/service-details">
                                <div className="ss-img">
                                    <h6>Emergency Situations </h6>
                                    <img src="./images/assistanceService.png" alt="Assistance Services" />
                                </div>
                            </Link><div className="ss-content"><Link to="/service-details">
                                <h3>Emergency Situations</h3>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisic-<br />ing elit, sed do eiusmod tempor incididunt ut <br />   labore et dolore magna aliqua. Ut enim
                                </p>
                            </Link><div className="butns"><Link to="/service-details">
                            </Link><Link to="/service-details" className="p-btn" tabIndex={0}><span>View Services</span><img src="./images/red-btn.png" alt="Go Btn" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
