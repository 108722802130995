import React from 'react'

export default function Loader() {
    return (
        <div>
            <div className="Loading">
                <div className="dot-spinner">
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                </div>

            </div>
        </div>
    )
}
