import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import About from "./pages/About";
import Services from "./pages/Services";
import Visa_application from "./pages/Visa_application";
import Service_details from "./pages/Service_details";
import Guidance_advisory from "./pages/Guidance_advisory";
import Events from "./pages/Events";
import Event_details from "./pages/Event_details";
import Republic_ongo from "./pages/Republic_ongo";
import Contact from "./pages/Contact";
import './App.css';
import { useEffect, useState } from "react";
import Loader from "./components/Loader";

function App() {

  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => setLoading(false), 700);
    return () => clearTimeout(timer);
  }, [location]);

  return (
    <>
      {loading && <Loader />}
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/visa-application" element={<Visa_application />} />
        <Route path="/service-details" element={<Service_details />} />
        <Route path="/guidance-advisory" element={<Guidance_advisory />} />
        <Route path="/events" element={<Events />} />
        <Route path="/event-details" element={<Event_details />} />
        <Route path="/republic-of-congo" element={<Republic_ongo />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </>
  );
}

const WrappedApp = () => (
  <Router>
    <App />
  </Router>
);
export default WrappedApp;
