import React from 'react'
import { Link } from 'react-router-dom'

export default function Guidance_advisory() {
    return (
        <div>
            <section className="innerPagebanner">
                <div className="container">
                    <div className="wraper">
                        <div className="row">
                            <div className="content">
                                <h1>Guidance / Advisory</h1>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><span>-</span></li>
                                    <li><Link to="/guidance-advisory">Guidance / Advisory</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ss-buts" id="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-12 colLeft">
                            <div className="content">
                                <h4><span>Strengthening Bilateral <br />
                                    Relations and Serving Our Citizens</span>
                                </h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-12 slidbuts"> </div>
                        <div className="col-lg-6 col-12 colRight">
                            <ul className="social">
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-linkedin" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-twitter" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-instagram" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-facebook" aria-hidden="true" /></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about" id="economicRow1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colLeft">
                            <div className="ab-img wow fadeInRight" style={{ visibility: 'visible', animationName: 'fadeInRight' }}>
                                <img src="./images/business-oppertunites.png" className="embsader" alt="business-oppertunites" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 colright">
                            <div className="ab-text wow fadeInLeft" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
                                <h2>Business Opportunities </h2>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem <br />
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an <br />
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.  <br />
                                    It has survived not only five centuries.
                                </p>
                                <p>But also the leap into electronic typesetting, remaining essentially unchanged. It was <br />
                                    popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum <br />
                                    passages, and more recently with desktop publishing software like Aldus PageMaker
                                </p>
                                <ul>
                                    <li><img src="./images/checked.png" alt="Checked" /><h6>exercitation ullamco laboris</h6></li>
                                    <li><img src="./images/checked.png" alt="Checked" /><h6>exercitation ullamco laboris</h6></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mission">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="content">
                                <h2>Tourism and the Environment</h2>
                                <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem <br />
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an <br />
                                    unknown printer took a galley of type and scrambled it to make a type specimen <br />
                                    book. It has survived not only five centuries, but also the leap into electronic <br />
                                    typesetting, remaining essentially unchanged.
                                </p>
                                <p> It was popularised in the 1960s with the release of Letraset sheets containing Lorem <br />
                                    Ipsum passages, and more recently with desktop publishing software like Aldus <br />
                                    PageMaker including versions of Lorem Ipsum.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="mission-img">
                                <img src="./images/tour_enviroment.png" alt="Tourism and the Environment" className="tour" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="according">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colLeft">
                            <div className="ab-img wow fadeInRight">
                                <img src="./images/traval_advice.png" alt="Travel Advice" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 colright">
                            <div className="ab-text wow fadeInLeft">
                                <h2>Travel Advice</h2>
                                <p className="para">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has <br />
                                    been the industry's standard dummy text ever since the 1500s, when an unknown printer took a <br />
                                    galley of type and scrambled it to make a type specimen book.
                                </p>
                                <div className="accordion" id="accordionPanelsStayOpenExample">
                                    <div className="accordion-item">
                                        <h3 className="accordion-header">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                                Tourist Visa
                                            </button>
                                        </h3>
                                        <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                            <div className="accordion-body">
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem <br />
                                                    Ipsum has been the industry's standard dummy text.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h3 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                                Business Visa
                                            </button>
                                        </h3>
                                        <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem <br />
                                                    Ipsum has been the industry's standard dummy text.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h3 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                                Official/Diplomatic Visa
                                            </button>
                                        </h3>
                                        <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem <br />
                                                    Ipsum has been the industry's standard dummy text.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about naturalLandscapes">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colright">
                            <div className="ab-text wow fadeInLeft" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
                                <h2>National Parks and Landscapes of Congo </h2>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem <br />
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an <br />
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.  <br />
                                    It has survived not only five centuries.
                                </p>
                                <p>But also the leap into electronic typesetting, remaining essentially unchanged. It was <br />
                                    popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum <br />
                                    passages, and more recently with desktop publishing software like Aldus PageMaker
                                </p>
                                <ul>
                                    <li><img src="./images/checked.png" alt="Checked" /><h6>exercitation ullamco laboris</h6></li>
                                    <li><img src="./images/checked.png" alt="Checked" /><h6>exercitation ullamco laboris</h6></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 colLeft">
                            <div className="ab-img wow fadeInRight" style={{ visibility: 'visible', animationName: 'fadeInRight' }}>
                                <img src="./images/naturalLandscapes.jpg" className="embsader" alt="National Parks and Landscapes of Congo" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}
