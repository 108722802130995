import React from 'react'
import { Link } from 'react-router-dom'

export default function Republic_ongo() {
    return (
        <div>
            <section className="innerPagebanner">
                <div className="container">
                    <div className="wraper">
                        <div className="row">
                            <div className="content">
                                <h1>About the Republic of Congo</h1>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><span>-</span></li>
                                    <li><Link to="republic-of-congo">About the Republic of Congo</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ss-buts" id="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-12 colLeft">
                            <div className="content">
                                <h4><span>Strengthening Bilateral <br />
                                    Relations and Serving Our Citizens</span>
                                </h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-12 slidbuts"> </div>
                        <div className="col-lg-6 col-12 colRight">
                            <ul className="social">
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-linkedin" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-twitter" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-instagram" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-facebook" aria-hidden="true" /></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about" id="abouPage">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colLeft">
                            <div className="ab-img wow fadeInRight">
                                <img src="./images/about-img.png" alt="About Us" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 colright">
                            <div className="ab-text wow fadeInLeft">
                                <h2> The Republic of Congo</h2>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem <br />
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an <br />
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.  <br />
                                    It has survived not only five centuries.
                                </p>
                                <p>But also the leap into electronic typesetting, remaining essentially unchanged. It was <br />
                                    popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum <br />
                                    passages, and more recently with desktop publishing software like Aldus <br />
                                </p>
                                <ul>
                                    <li><img src="./images/checked.png" alt="Checked" /><h6>exercitation ullamco laboris</h6></li>
                                    <li><img src="./images/checked.png" alt="Checked" /><h6>exercitation ullamco laboris</h6></li>
                                </ul>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem <br />
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an <br />
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.  <br />
                                    It has survived not only five centuries.
                                </p>
                                <p>But also the leap into electronic typesetting, remaining essentially unchanged. It was <br />
                                    popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mission">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="content">
                                <h2>The President of the Republic of Congo</h2>
                                <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem <br />
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an <br />
                                    unknown printer took a galley of type and scrambled it to make a type specimen <br />
                                    book. It has survived not only five centuries, but also the leap into electronic <br />
                                    typesetting, remaining essentially unchanged.
                                </p>
                                <p> It was popularised in the 1960s with the release of Letraset sheets containing Lorem <br />
                                    Ipsum passages, and more recently with desktop publishing software like Aldus <br />
                                    PageMaker including versions of Lorem Ipsum.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="mission-img">
                                <img src="./images/presidentofcongo.png" alt="Mission" className="prsedent " />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about congogovernment">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colLeft">
                            <div className="ab-img wow fadeInRight" style={{ visibility: 'visible', animationName: 'fadeInRight' }}>
                                <img src="./images/GovernmentCongo.png" alt="The Government of the Republic of Congo" className="embsader" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 colright">
                            <div className="ab-text wow fadeInLeft" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
                                <h2>The Government of the Republic of Congo</h2>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem <br />
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an <br />
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.  <br />
                                    It has survived not only five centuries.
                                </p>
                                <p>But also the leap into electronic typesetting, remaining essentially unchanged. It was <br />
                                    popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum <br />
                                    passages, and more recently with desktop publishing software like Aldus PageMaker
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="economic deplomcycp">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colLeft">
                            <div className="ab-text fadeInLeft">
                                <h2>Diplomacy of the Republic of Congo</h2>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem <br />
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an <br />
                                    unknown printer took a galley of type and scrambled it to make a type specimen book. <br />
                                    It has survived not only five centuries.
                                </p>
                                <p>But also the leap into electronic typesetting, remaining essentially unchanged. It was <br />
                                    popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum <br />
                                    passages, and more recently with desktop publishing software like Aldus <br />
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12  colright">
                            <div className="ab-img wow fadeInRight" style={{ visibility: 'visible', animationName: 'fadeInRight' }}>
                                <img src="./images/DiplomacyofCongo.png" alt="Diplomacy of the Republic of Congo" className="deplomcy" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about congobasin">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colLeft">
                            <div className="ab-img wow fadeInRight" style={{ visibility: 'visible', animationName: 'fadeInRight' }}>
                                <img src="./images/congobasin.jpg" alt="Congo Basin" className="embsader" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 colright">
                            <div className="ab-text wow fadeInLeft" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
                                <h2>Congo Basin</h2>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem <br />
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an <br />
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.  <br />
                                    It has survived not only five centuries.
                                </p>
                                <p>But also the leap into electronic typesetting, remaining essentially unchanged. It was <br />
                                    popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum <br />
                                    passages, and more recently with desktop publishing software like Aldus PageMaker
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="economic deplomcycp">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colLeft ">
                            <div className="ab-text fadeInLeft ">
                                <h2>Geography</h2>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem <br />
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an <br />
                                    unknown printer took a galley of type and scrambled it to make a type specimen book. <br />
                                    It has survived not only five centuries.
                                </p>
                                <p>But also the leap into electronic typesetting, remaining essentially unchanged. It was <br />
                                    popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum <br />
                                    passages, and more recently with desktop publishing software like Aldus <br />
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12  colright ">
                            <div className="ab-img wow fadeInRight" style={{ visibility: 'visible', animationName: 'fadeInRight' }}>
                                <img src="./images/Geography.png" alt="Geography" className="deplomcy" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about congobasin">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colLeft">
                            <div className="ab-img wow fadeInRight" style={{ visibility: 'visible', animationName: 'fadeInRight' }}>
                                <img src="./images/history.png" alt="History" className="embsader" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 colright">
                            <div className="ab-text wow fadeInLeft" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
                                <h2>History</h2>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem <br />
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an <br />
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.  <br />
                                    It has survived not only five centuries.
                                </p>
                                <p>But also the leap into electronic typesetting, remaining essentially unchanged. It was <br />
                                    popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum <br />
                                    passages, and more recently with desktop publishing software like Aldus PageMaker
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="economic deplomcycp">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colLeft ">
                            <div className="ab-text fadeInLeft uulinks">
                                <h2>useful links </h2>
                                <ul className="us-links">
                                    <li><Link to="#">Lorem ipsum dolor sit amet </Link></li>
                                    <li><Link to="#">Lorem ipsum dolor sit amet </Link></li>
                                    <li><Link to="#">Lorem ipsum dolor sit amet </Link></li>
                                    <li><Link to="#">Lorem ipsum dolor sit amet </Link></li>
                                    <li><Link to="#">Lorem ipsum dolor sit amet </Link></li>
                                    <li><Link to="#">Lorem ipsum dolor sit amet </Link></li>
                                    <li><Link to="#">Lorem ipsum dolor sit amet </Link></li>
                                    <li><Link to="#">Lorem ipsum dolor sit amet </Link></li>
                                    <li><Link to="#">Lorem ipsum dolor sit amet </Link></li>
                                    <li><Link to="#">Lorem ipsum dolor sit amet </Link></li>
                                    <li><Link to="#">Lorem ipsum dolor sit amet </Link></li>
                                    <li><Link to="#">Lorem ipsum dolor sit amet </Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12  colright ">
                            <div className="ab-img wow fadeInRight" style={{ visibility: 'visible', animationName: 'fadeInRight' }}>
                                <img src="./images/ab-page-ambassy.png" alt="ambassy" className="deplomcy" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>

    )
}
