import React from 'react'
import { Link } from 'react-router-dom'

export default function Visa_application() {
    return (
        <div>
            <section className="innerPagebanner">
                <div className="container">
                    <div className="wraper">
                        <div className="row">
                            <div className="content">
                                <h1>Visa Applications</h1>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><span>-</span></li>
                                    <li><Link to="/services">Services</Link></li>
                                    <li><span>-</span></li>
                                    <li><Link to="/visa-application">Visa Applications</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ss-buts" id="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-12 colLeft">
                            <div className="content">
                                <h4><span>Strengthening Bilateral <br />
                                    Relations and Serving Our Citizens</span>
                                </h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 col-12 slidbuts">
                            <div className="download-btn">
                                <Link to="#">download Visa application</Link>
                            </div>
                        </div>
                        <div className="col-lg-3 col-12 colRight">
                            <ul className="social">
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-linkedin" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-twitter" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-instagram" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-facebook" aria-hidden="true" /></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colLeft">
                            <div className="ab-img wow fadeInRight" style={{ visibility: 'visible', animationName: 'fadeInRight' }}>
                                <img src="./images/visa-apv2.png" alt="Apply For Visa" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 colright">
                            <div className="ab-text wow fadeInLeft visa-app" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
                                <h2>Visa for the <br />Republic of Congo </h2>
                                <h4>01 Valid Passport Requirement</h4>
                                <p className="pb-1">A valid passport is required for all British citizens and foreign nationals entering the <br />Republic of Congo.</p>
                                <h4>02 Evidence Presentation</h4>
                                <p>Visitors must present: <br />
                                    Evidence of the visit’s purpose.<br />
                                    Means of support for the stay's duration.<br />
                                    Accommodation arrangements at border controls.
                                </p>
                                <h4>03 Visa Issuance at Port of Entry</h4>
                                <p>No Congolese visa will be issued at any port of entry in the Republic of Congo.</p>
                                <h4>04 Visa Application Submission</h4>
                                <p>All visa applications must be submitted to the Consulate Section of the Embassy of the <br /> Republic of Congo in the United Kingdom before the departure date.</p>
                                <div className="butns">
                                    <Link to="#" className="p-btn"><span>Visa Apply</span><img src="./images/red-btn.png" alt="Go Button" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="according" id="visa_required">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colright">
                            <div className="ab-text wow fadeInLeft" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
                                <h2>Processing Time <br />for Visa Applications</h2>
                                <h4>01 Standard Processing Time</h4>
                                <p>The processing time for all consular service requests is 5-7 business days from the day <br />
                                    a visa application or consular service request is received.
                                </p>
                                <h4>02 Expedited Service</h4>
                                <p>Expedited service is available and takes 24 hours.</p>
                                <h4>03 Visa Issuance at Port of Entry</h4>
                                <p><b>Visa applicants are advised to submit their applications:</b> <br />
                                    At least one month (30 days) before their departure date.
                                    No more than one and a half months (45 days) before their departure date.
                                </p>
                                <div className="butns">
                                    <Link to="#" className="p-btn"><span>Apply Now</span><img src="./images/red-btn.png" alt="Go Button" /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 colLeft">
                            <div className="ab-img wow fadeInRight" style={{ visibility: 'visible', animationName: 'fadeInRight' }}>
                                <img src="./images/visa-reqv2.png" alt="Travel Advice" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="according">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colLeft">
                            <div className="ab-img wow fadeInRight">
                                <img src="./images/aply-vs22.png" alt="Apply For Visa" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 colright">
                            <div className="ab-text wow fadeInLeft">
                                <h2>Types of Visas</h2>
                                <p className="para">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has <br />
                                    been the industry's standard dummy text ever since the 1500s, when an unknown printer took a <br />
                                    galley of type and scrambled it to make a type specimen book.
                                </p>
                                <div className="accordion" id="accordionPanelsStayOpenExample">
                                    <div className="accordion-item">
                                        <h3 className="accordion-header">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                                Tourist Visa
                                            </button>
                                        </h3>
                                        <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                            <div className="accordion-body">
                                                <p className="pb-2">Tourist Visa: Issued at any accredited Congolese Consulate, this visa allows tourists and visitors <br />
                                                    to enter the country through designated ports of entry, such as airports and harbours.
                                                </p>
                                                <h4>Types of Tourist Visas: </h4>
                                                <ul>
                                                    <li><span>- 15 Days</span></li>
                                                    <li><span>- 30 Days</span></li>
                                                    <li><span>- 45 Days</span></li>
                                                    <li><span>- 60 Days</span></li>
                                                </ul>
                                                <div className="butns">
                                                    <Link to="#" className="p-btn"><span>Apply Now </span><img src="./images/red-btn.png" alt="Go Button" /></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h3 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                                Business Visa
                                            </button>
                                        </h3>
                                        <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem <br />
                                                    Ipsum has been the industry's standard dummy text.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h3 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                                Official/Diplomatic Visa
                                            </button>
                                        </h3>
                                        <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem <br />
                                                    Ipsum has been the industry's standard dummy text.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="visa-detial">
                <div className="container">
                    <div className="row">
                        <h2 className="pb-4">Visa Requirements</h2>
                        <strong>A fully completed visa application form:</strong>
                        <p>Submit one copy of the visa application form. Print, complete, and sign it. Leaving any sections blank will delay your visa approval process.
                            Note that all listed documents are required. Failure to provide all the necessary documents may result in the rejection of your application.
                        </p>
                        <strong>Two passport-sized color photographs:</strong>
                        <p>Photos must be 2 x 2 inches (51 x 51 mm) in size, coloured, and have a white background, showing a full front view of the face.
                            The photos must have been taken within the last 6 months to reflect your current appearance.
                        </p>
                        <strong>A valid passport:</strong>
                        <p>Your passport should be valid for at least 6 months before your departure date.</p>
                        <strong>An invitation letter or hotel reservation (for the tourist visa):</strong>
                        <p>A typewritten, notarized invitation letter (the invitation letter should be notarized at the City Council or Prefecture/Mairie)
                            stating the nature of the trip, including the name and address of the reference to be visited in the Republic of Congo.
                            The invitation letter must be addressed to the Embassy of the Republic of Congo, Visa Section, and should be valid for at least 3 months (90 days).
                            <br />
                            Parents traveling with children or legal guardians traveling with dependents must provide proof of parenthood or legal custody, such as a court order or birth certificate.
                            A photocopy of a valid passport should be submitted for those planning to travel with minors. All visa applicants (including former Congolese citizens) must provide an invitation letter from their host in the Republic of Congo, a hotel reservation, and a form of identity card of the host (passport, Congolese legal residence permit or visa, and national Congolese ID). The city of residence mentioned in the invitation letter should match the city of residence stated on the Congolese visa application. <br />
                            For family reunification (regroupement familial), proof of family affiliation, such as birth certificates, adoption documents, and legal marriage certificates, should be included in the application package. For funeral purposes, a death certificate must be provided, and for wedding purposes, an invitation card to the ceremony must be included.
                        </p>
                        <strong>For minors applying for a visa:</strong>
                        <p>Provide a copy of the adult parent or legal guardian's passport and flight itinerary. <br />
                            A parental authorization signed by a notary, allowing the other parent or a third person to travel with the child. <br />
                            A copy of the UK residency permit or UK visa (for non-UK citizens) as proof of legal residence in the United Kingdom.
                        </p>
                        <strong>A business letter (for the business visa):</strong>
                        <p>A typewritten, notarized business letter (notarized at the City Council or Prefecture/Mairie) on company letterhead stating the nature of the business, the name, and address of the reference to be contacted in the Republic of Congo. The invitation letter must be addressed to the Embassy of the Republic of Congo, Visa Section. No business visa will be granted without an official business letter.
                        </p>
                        <strong>Copy of airline tickets or itinerary:</strong>
                        <p>A copy of your airline ticket or reservation showing arrival and departure dates in the Republic of Congo.</p>
                        <strong>A prepaid envelope:</strong>
                        <p>A prepaid envelope with a TRACKING NUMBER to allow for the return of your passport. The Embassy cannot accept prepaid envelopes without a tracking number.
                        </p>
                        <div className="butns">
                            <Link to="#" className="p-btn"><span>download Visa application</span><img src="./images/red-btn.png" alt="Go Button" /></Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
