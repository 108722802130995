import React from 'react'
import { Link } from 'react-router-dom'

export default function Events() {
    return (
        <div>
            <section className="innerPagebanner">
                <div className="container">
                    <div className="wraper">
                        <div className="row">
                            <div className="content">
                                <h1>News &amp; Events</h1>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><span>-</span></li>
                                    <li><Link to="/events">News &amp; Events</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ss-buts" id="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-12 colLeft">
                            <div className="content">
                                <h4><span>Strengthening Bilateral <br />
                                    Relations and Serving Our Citizens</span>
                                </h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-12 slidbuts"> </div>
                        <div className="col-lg-6 col-12 colRight">
                            <ul className="social">
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-linkedin" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-twitter" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-instagram" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-facebook" aria-hidden="true" /></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="news" id="news">
                <div className="container">
                    <div className="heatest text-center">
                        <h2>Latest News &amp; Events</h2>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been <br />
                            the industry's standard dummy text.
                        </p>
                    </div>
                    <div className="row ">
                        <div className="col-lg-6 col-md-6  col-12 coleftSm">
                            <div className="postList">
                                <div className="row">
                                    <div className="col-lg-5 col-md-5 col-12">
                                        <div className="post-img">
                                            <Link to="/event-details">
                                                <img src="./images/psm1.png" alt="News" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-12">
                                        <div className="post-content">
                                            <Link to="/event-details">
                                                <h5>dolor sit amet consecteutr <br /> elit sed do quis</h5>
                                                <div className="user-box">
                                                    <div className="user-img">
                                                        <img src="./images/admin1.png" alt="Admin" />
                                                    </div>
                                                    <div className="user-name">
                                                        <h6>lora stevenson</h6>
                                                        <span><strong>June 12</strong></span>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="events.html" className="copy"><img src="./images/copy.png" alt="Copy" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="postList">
                                <div className="row">
                                    <div className="col-lg-5 col-md-5 col-12">
                                        <div className="post-img">
                                            <Link to="/event-details">
                                                <img src="./images/psm2.png" alt="News" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-12">
                                        <div className="post-content">
                                            <Link to="/event-details">
                                                <h5>dolor sit amet consecteutr <br /> elit sed do quis</h5>
                                                <div className="user-box">
                                                    <div className="user-img">
                                                        <img src="./images/admin1.png" alt="Admin" />
                                                    </div>
                                                    <div className="user-name">
                                                        <h6>lora stevenson</h6>
                                                        <span><strong>June 12</strong></span>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="events.html" className="copy"><img src="./images/copy.png" alt="Copy" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="postList">
                                <div className="row">
                                    <div className="col-lg-5 col-md-5 col-12">
                                        <div className="post-img">
                                            <Link to="/event-details">
                                                <img src="./images/psm3.png" alt="News" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-12">
                                        <div className="post-content">
                                            <Link to="/event-details">
                                                <h5>dolor sit amet consecteutr <br /> elit sed do quis</h5>
                                                <div className="user-box">
                                                    <div className="user-img">
                                                        <img src="./images/admin1.png" alt="Admin" />
                                                    </div>
                                                    <div className="user-name">
                                                        <h6>lora stevenson</h6>
                                                        <span><strong>June 12</strong></span>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="events.html" className="copy"><img src="./images/copy.png" alt="Copy" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 viewpost">
                            <div className="row">
                                <Link to="/event-details">
                                    <div className="bg-img">
                                        <img src="./images/plg2.png" alt="View Post" />
                                    </div>
                                </Link>
                                <div className="post-view">
                                    <Link to="/event-details">
                                        <h5>dolor sit amet consecteutr <br /> elit sed do quis</h5>
                                        <div className="user-box">
                                            <div className="user-img">
                                                <img src="./images/admin2.png" alt="Admin" />
                                            </div>
                                            <div className="user-name">
                                                <h6>lora stevenson</h6>
                                                <span><strong>June 12</strong></span>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="events.html" className="copy"><img src="./images/white-shap.png" alt="Copy" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}
