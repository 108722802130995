import React, { useEffect } from 'react'
import { useBootstrapPrefix } from 'react-bootstrap-v5/lib/esm/ThemeProvider';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

export default function Service_details() {
    var serv = {
        infinite: true,
        speed: 500,
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
    };
    return (
        <div>
            <section className="innerPagebanner">
                <div className="container">
                    <div className="wraper">
                        <div className="row">
                            <div className="content">
                                <h1>Visa Applications</h1>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><span>-</span></li>
                                    <li><Link to="/services">Services</Link></li>
                                    <li><span>-</span></li>
                                    <li><Link to="/service-details">Services Name</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ss-buts" id="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-12 colLeft">
                            <div className="content">
                                <h4><span>Strengthening Bilateral <br />
                                    Relations and Serving Our Citizens</span>
                                </h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-12 slidbuts"> </div>
                        <div className="col-lg-6 col-12 colRight">
                            <ul className="social">
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-linkedin" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-twitter" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-instagram" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-facebook" aria-hidden="true" /></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colLeft">
                            <div className="ab-img wow fadeInRight" style={{ visibility: 'visible', animationName: 'fadeInRight' }}>
                                <img src="./images/ss-visa-details.png" alt="Apply For Visa" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 colright">
                            <div className="ab-text wow fadeInLeft" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
                                <h2>PROCESSING TIME FOR <br />
                                    VISA APPLICATIONS</h2>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem <br />
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an <br />
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.  <br />
                                    It has survived not only five centuries.
                                </p>
                                <p>But also the leap into electronic typesetting, remaining essentially unchanged. It was <br />
                                    popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum <br />
                                    passages, and more recently with desktop publishing software like Aldus <br />
                                </p>
                                <div className="butns">
                                    <Link to="#" className="p-btn"><span>Visa Apply</span><img src="./images/red-btn.png" alt="Go Button" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="vs-require">
                <div className="container-fluid">
                    <div className="heatest text-center">
                        <h2>VISA REQUIREMENTS</h2>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been <br />
                            the industry's standard dummy text.
                        </p>
                    </div>
                    <div className="visa-req">
                        <Slider {...serv}>
                            <div className="item">
                                <div className="card">
                                    <h3>Consec Adipisicing</h3>
                                    <p>Duis aute irure dolor in <br />reprehenderit in voluptate velit <br /> esse cillum dolore <br />
                                        eu fugiat nulla pariatu.
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="card">
                                    <h3>Consec Adipisicing</h3>
                                    <p>Duis aute irure dolor in <br />reprehenderit in voluptate velit <br /> esse cillum dolore <br />
                                        eu fugiat nulla pariatu.
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="card">
                                    <h3>Consec Adipisicing</h3>
                                    <p>Duis aute irure dolor in <br />reprehenderit in voluptate velit <br /> esse cillum dolore <br />
                                        eu fugiat nulla pariatu.
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="card">
                                    <h3>Consec Adipisicing</h3>
                                    <p>Duis aute irure dolor in <br />reprehenderit in voluptate velit <br /> esse cillum dolore <br />
                                        eu fugiat nulla pariatu.
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="card">
                                    <h3>Consec Adipisicing</h3>
                                    <p>Duis aute irure dolor in <br />reprehenderit in voluptate velit <br /> esse cillum dolore <br />
                                        eu fugiat nulla pariatu.
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="card">
                                    <h3>Consec Adipisicing</h3>
                                    <p>Duis aute irure dolor in <br />reprehenderit in voluptate velit <br /> esse cillum dolore <br />
                                        eu fugiat nulla pariatu.
                                    </p>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>
            <section className="according">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colLeft">
                            <div className="ab-img wow fadeInRight">
                                <img src="./images/visa-aplication-occording.png" alt="Apply For Visa" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 colright">
                            <div className="ab-text wow fadeInLeft">
                                <h2>Types of Visas</h2>
                                <p className="para">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has <br />
                                    been the industry's standard dummy text ever since the 1500s, when an unknown printer took a <br />
                                    galley of type and scrambled it to make a type specimen book.
                                </p>
                                <div className="accordion" id="accordionPanelsStayOpenExample">
                                    <div className="accordion-item">
                                        <h3 className="accordion-header">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                                Tourist Visa
                                            </button>
                                        </h3>
                                        <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                            <div className="accordion-body">
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem <br />
                                                    Ipsum has been the industry's standard dummy text.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h3 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                                Business Visa
                                            </button>
                                        </h3>
                                        <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem <br />
                                                    Ipsum has been the industry's standard dummy text.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h3 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                                Official/Diplomatic Visa
                                            </button>
                                        </h3>
                                        <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem <br />
                                                    Ipsum has been the industry's standard dummy text.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}
