import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import $ from 'jquery'

export default function Header() {
    const location = useLocation();
    useEffect(() => {
        var str = window.location.href;
        $("#menu>li>a").each(function () {
            if (this.href === str) {
                $("#menu li").removeClass("active");
                $(this).parent().addClass("active");
            }
        });
    }, [location.pathname])
    return (
        <div>
            <header>
                <div className="menuSec">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2 col-sm-3 col-xs-6">
                                <div className="header-logo">
                                    <Link to="/"><img src="images/logo.png" alt="img" /></Link>
                                </div>
                            </div>
                            <div className="col-md-10 d-none d-md-block">
                                <ul id="menu">
                                    <li className="active"><Link to="/">Home</Link></li>
                                    <li><Link to="/about">About Us</Link></li>
                                    <li><Link to="/services"> Consular Services</Link></li>
                                    <li><Link to="/guidance-advisory"> Guidance/Advisory </Link></li>
                                    <li><Link to="/events">News &amp; Events </Link></li>
                                    <li><Link to="/republic-of-congo">Republic Of Congo</Link></li>
                                    <li><Link to="/contact">Contact Us</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

        </div>
    )
}
